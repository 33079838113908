const state = {
    navLink: [
        {
            name: 'Home',
            icon: 'home',
            routeLink:'/',
            child: null,
            permission: ['user','admin'],
        },
        {
            name: 'ABOUT',
            icon: 'people-carry',
            routeLink: '/about',
            child: null,
            permission: ['user']
        },
        {
            name: 'GALLERY',
            icon: 'eye',
            routeLink: '/gallery',
            child: null,
            permission: ['user']
        },
        {
            name: 'CONTACT',
            icon: 'cloud',
            routeLink:'/contact',
            child: null,
            permission: ['user'],
        },
        {
            name: 'TERMS & CONDITIONS',
            icon: 'copy',
            routeLink: '/terms',
            child: null,
            permission: ['user']
        },
    ]
}

const getters = {
    allNav: (state) => state.navLink
}

export default{
    state,
    getters
}
