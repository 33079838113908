<template>
  <p style="display: none"></p>
</template>

<script>
export default {
  name: 'HelloI18n'
}
</script>

<i18n>
{
  "en": {
    "hello": ""
  }
}
</i18n>
