const state = {
    allOrder: [
    ],
    fields:[
        {
            key: 'id',
            variant: 'info',
            sortable: true
        },
        {
            key:'customerName'
        },
        {
            key:'address'
        },
        {
            key:'phone'
        },
        {
            key:'token',
        },
        {
            key:'totalPrice'
        },
        {
            key:'actions'
        },
        {
            key:'order'
        }
    ]
}

const getters = {
    fields: (state) => state.fields
}

const actions = {

}

const mutations = {

}
 
export default{
    state,
    getters,
    actions,
    mutations
}