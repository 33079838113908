import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/users/Home.vue'
import Contact from '@/views/Contact'
import Sent from '@/views/Sent'
import About from '@/views/users/About'
import Terms from '@/views/users/Terms'
import Gallery from '@/views/users/Gallery'
import PageNotFound from '@/views/PageNotFound';
import store from '@/store/index';

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/sent',
    name: 'Sent',
    component: Sent
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/gallery',
    name: 'Gallery',
    component: Gallery
  },
    {
      path: '/terms',
      name: 'Terms',
      component: Terms
    },
  //always put after all the other routes
  {
    path: '*',
    component: PageNotFound
  }
]

const router = new VueRouter({
  mode: 'hash',
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to,from,next) =>{
  if(to.matched.some(record => record.meta.requiresAuth)){
    if(store.state.Auth.isAuth){
      next();
    }
    else{
      next('/account');
      // next()
    }
  }
  else {
    next();
  }
});

export default router
