<template>
  <div class="page">
    <!--========================================================
                              HEADER
    =========================================================-->
    <header class="page-header page-header--skin-1">
      <!-- RD Navbar -->
      <div class="rd-navbar-wrap">
      </div>
      <!-- END RD Navbar -->

      <div class="container">
        <div class="row">
          <div class="col-sm-6 col-lg-5 col-lg-preffix-1 wow fadeInLeft moto-text_system"
               style="background-image: url(https://www.wdbrownell.com/wp-content/uploads/2021/11/doors-link.jpg);">
            <div class="moto-text_system_container">
              <div class="row">
                <div class="col-md-6">
                  <span class="moto-text_system_12">{{$t('DIVERSITY DOORS') }}</span>
                </div>
                <div class="col-md-6 moto-text_system_12_5">
                  <p class="moto-text_system_13">{{$t('Choose anything you need from a wide range of doors') }}.</p>
                </div>
              </div>
            </div>

          </div>
          <div class="col-sm-6 col-lg-5 col-lg-preffix-1 wow fadeInLeft moto-text_system"
               style="background-image: url(https://www.wdbrownell.com/wp-content/uploads/2021/10/family-farmhouse-6.jpeg);">
            <div class="moto-text_system_container">
              <div class="row">
                <div class="col-md-6">
                  <span class="moto-text_system_12">{{$t('QUALITY WINDOWS') }}</span>
                </div>
                <div class="col-md-6 moto-text_system_12_5">
                  <p class="moto-text_system_13">
                    {{$t('We provides the best quality and variety of windows for homes and offices') }}.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </header>
    <!--========================================================
                              CONTENT
    =========================================================-->
    <main class="page-content">
      <!-- ABOUT -->
      <section class="well-md text-center">
        <div class="container">
          <span class="icon icon-md icon-default material-icons-flash_on wow fadeInUp"
                style="visibility: visible; animation-name: fadeInUp;"></span>
          <h1 class="section-head-left">{{ $t('ABOUT') }}</h1>
          <div class="row text-sm-left">
            <div class="col-sm-6 wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
              <div class="content">
                <div class="image-wrap shadow-right">
                  <img src="@/assets/home/lg_page-1_img01.jpg" data-srcset-base="@/assets/home/home"
                       data-srcset-ext="_page-1_img01.jpg" data-srcset="sm 1101w, md 1369w, lg 1400w" alt="" width="570"
                       height="570">
                </div>
              </div>
            </div>
            <div class="col-sm-preffix-1 col-sm-5 col-md-4 inset-1 wow fadeInRight"
                 style="visibility: visible; animation-name: fadeInRight;">
              <div class="line-right">
                <h4>{{$t('Clients choose us') }}!</h4>
                <p class="wow fadeInUp" data-wow-delay="0.1s"
                   style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInUp;"><strong>RenoMaar</strong> {{$t('about-us-2')}}</p>
                <p class="wow fadeInUp" data-wow-delay="0.2s"
                   style="visibility: visible; animation-delay: 0.2s; animation-name: fadeInUp;">{{$t('come-try')}}</p>
                <a href="/#/about" class="link wow fadeInUp" data-wow-delay="0.3s"
                   style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">{{$t('See More')}}</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- END ABOUT-->

      <!-- SERVICES -->
      <section class="well-md text-center">
        <div class="container">
          <span class="icon icon-md icon-default material-icons-flash_on wow fadeInUp"
                style="visibility: hidden; animation-name: fadeInUp;"></span>
          <h1 class="section-head-right">STEPS</h1>
          <div class="row text-sm-left">
            <div class="col-sm-1 col-sm-preffix-0 col-sm-push-0 wow fadeInUp"
                 style="visibility: visible; animation-name: fadeInUp;">
              <div class="content">
                <div class="content__head">
                  <span class="line"></span>
                </div>
                <div class="image-wrap shadow-left"></div>
              </div>
            </div>
            <div class="col-sm-12 inset-1 col-sm-push-1 wow fadeInLeft">
              <div class="row">
                <div class="col-md-3">
                  <div class="box">
                    <div class="ribbon-1">
                      <span class="ribon-title">Step 1</span>
                    </div>
                    <div class="sina-content-box-icon">
                      <img src="https://windowsbyjasmine.com/wp-content/uploads/2022/05/home-photos-6-scaled.jpg"
                           alt="IN-HOME CONSULTATION">
                    </div>
                    <div class="sina-content-box-content">
                      <h3 class="sina-content-box-title">{{ $t('step-1-title') }}</h3>
                      <div class="sina-content-box-desc">{{ $t('step-1-content') }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="box">
                    <div class="ribbon-2">
                      <span class="ribon-title">Step 2</span>
                    </div>
                    <div class="sina-content-box-icon">
                      <img src="https://windowsbyjasmine.com/wp-content/uploads/2022/05/home-photos-4-scaled.jpg"
                           alt="IN-HOME CONSULTATION">
                    </div>
                    <div class="sina-content-box-content">
                      <h3 class="sina-content-box-title">{{ $t('step-2-title') }}</h3>
                      <div class="sina-content-box-desc">{{ $t('step-2-content') }}</div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="box">
                    <div class="ribbon-3">
                      <span class="ribon-title">Step 3</span>
                    </div>
                    <div class="sina-content-box-icon">
                      <img src="https://windowsbyjasmine.com/wp-content/uploads/2022/05/home-photos-3-scaled.jpg"
                           alt="IN-HOME CONSULTATION">
                    </div>
                    <div class="sina-content-box-content">
                      <h3 class="sina-content-box-title">{{ $t('step-3-title') }}</h3>
                      <div class="sina-content-box-desc">{{ $t('step-3-content') }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="box">
                    <div class="ribbon-4">
                      <span class="ribon-title">Step 4</span>
                    </div>
                    <div class="sina-content-box-icon">
                      <img src="https://windowsbyjasmine.com/wp-content/uploads/2022/05/home-photos-2-scaled.jpg"
                           alt="IN-HOME CONSULTATION">
                    </div>
                    <div class="sina-content-box-content">
                      <h3 class="sina-content-box-title">{{ $t('step-4-title') }}</h3>
                      <div class="sina-content-box-desc">{{ $t('step-4-content') }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- END SERVICES-->

      <!-- Testimony -->
      <section class="well-md text-center">
        <div class="container">
          <span class="icon icon-md icon-default material-icons-flash_on wow fadeInUp"
                style="visibility: visible; animation-name: fadeInUp;"></span>
          <h1 class="section-head-left">Testimony</h1>
          <div class="row text-sm-left">
            <div class="col-sm-6 wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
              <div class="content">
                <div class="content__head">
                  <span class="line">{{$t('best-service')}}</span>
                </div>
                <div class="image-wrap shadow-right">
                  <img src="@/assets/home/lg_page-1_img03.jpg" data-srcset-base="@/assets/home/home"
                       data-srcset-ext="_page-1_img03.jpg" data-srcset="sm 1101w, md 1369w, lg 1400w" alt="" width="570"
                       height="570">
                </div>
              </div>
            </div>
            <div class="col-sm-preffix-1 col-sm-5 col-md-4 inset-1 wow fadeInRight"
                 style="visibility: visible; animation-name: fadeInRight;">
              <div class="line-right">
                <div class="ugb-icon-inner-svg">
                  <svg class="ugb-custom-icon" id="quote_3_" data-name="quote (3)" xmlns="http://www.w3.org/2000/svg"
                       width="30" height="21.166" viewBox="0 0 30 21.166">
                    <path id="Path_278" data-name="Path 278"
                          d="M9.431,19.24a16.956,16.956,0,0,0,3.09,4.88,1.475,1.475,0,0,1,.14,1.78,1.448,1.448,0,0,1-1.24.68,1.22,1.22,0,0,1-.42-.06c-2.94-.86-9.81-3.91-10-13.69a7.206,7.206,0,0,1,6.28-7.38,7.01,7.01,0,0,1,7.75,6.98,6.931,6.931,0,0,1-5.6,6.81Z"
                          transform="translate(14.969 -5.414)" fill="#216648"></path>
                    <path id="Path_279" data-name="Path 279"
                          d="M23.26,5.45A7.009,7.009,0,0,1,31,12.43a6.931,6.931,0,0,1-5.6,6.81,16.956,16.956,0,0,0,3.09,4.88,1.475,1.475,0,0,1,.14,1.78,1.448,1.448,0,0,1-1.24.68,1.22,1.22,0,0,1-.42-.06c-2.94-.86-9.81-3.91-10-13.69v-.14a7.2,7.2,0,0,1,6.29-7.24Z"
                          transform="translate(-16.97 -5.414)" fill="#216648"></path>
                  </svg>
                </div>
                <h4 class="wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
                  <strong>{{ $t('testimony-1') }}</strong>
                </h4>
                <p class="wow fadeInUp" data-wow-delay="0.1s"
                   style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInUp;">{{$t('and-of-course')}},
                  {{$t('billiards and wide-screen sports. Welcome home, gentlemen')}}.</p>
                <a href="/#/gallery" class="link wow fadeInUp" data-wow-delay="0.2s"
                   style="visibility: visible; animation-delay: 0.2s; animation-name: fadeInUp;">{{$t('Read More')}}</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- END Testimony-->

      <!-- Prices -->
      <section class="well-md text-center">
        <div class="container">
          <span class="icon icon-md icon-default material-icons-flash_on wow fadeInUp"
                style="visibility: visible; animation-name: fadeInUp;"></span>
          <div class="row text-sm-left">
            <div class="col-sm-6 col-sm-preffix-1 col-sm-push-2 wow fadeInUp"
                 style="visibility: visible; animation-name: fadeInUp;">
              <div class="content">
                <div class="content__head">
                  <span class="line"></span>
                </div>
                <div class="image-wrap shadow-left">
                  <img src="@/assets/home/lg_page-1_img04.jpg" data-srcset-base="@/assets/home/home"
                       data-srcset-ext="_page-1_img04.jpg" data-srcset="sm 1101w, md 1369w, lg 1400w" alt="" width="570"
                       height="570">
                </div>
              </div>
            </div>
            <div class="col-sm-preffix-1 col-sm-4 inset-1 col-sm-push-1 wow fadeInLeft"
                 style="visibility: visible; animation-name: fadeInLeft;">
              <div class="line-left">
                <div class="pricing-table wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
                  <div class="ugb-icon-inner-svg">
                    <svg class="ugb-custom-icon" id="quote_3_" data-name="quote (3)" xmlns="http://www.w3.org/2000/svg"
                         width="30" height="21.166" viewBox="0 0 30 21.166">
                      <path id="Path_278" data-name="Path 278"
                            d="M9.431,19.24a16.956,16.956,0,0,0,3.09,4.88,1.475,1.475,0,0,1,.14,1.78,1.448,1.448,0,0,1-1.24.68,1.22,1.22,0,0,1-.42-.06c-2.94-.86-9.81-3.91-10-13.69a7.206,7.206,0,0,1,6.28-7.38,7.01,7.01,0,0,1,7.75,6.98,6.931,6.931,0,0,1-5.6,6.81Z"
                            transform="translate(14.969 -5.414)" fill="#216648"></path>
                      <path id="Path_279" data-name="Path 279"
                            d="M23.26,5.45A7.009,7.009,0,0,1,31,12.43a6.931,6.931,0,0,1-5.6,6.81,16.956,16.956,0,0,0,3.09,4.88,1.475,1.475,0,0,1,.14,1.78,1.448,1.448,0,0,1-1.24.68,1.22,1.22,0,0,1-.42-.06c-2.94-.86-9.81-3.91-10-13.69v-.14a7.2,7.2,0,0,1,6.29-7.24Z"
                            transform="translate(-16.97 -5.414)" fill="#216648"></path>
                    </svg>
                  </div>
                  <h4 class="wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
                    <strong>{{ $t('testimony-2') }}</strong>
                  </h4>
                </div>
                <a href="/#/contact" class="link wow fadeInUp" data-wow-delay="0.1s"
                   style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInUp;">{{$t('appointment')}}</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- END Prices-->

      <!-- Mail us -->
      <section class="well-md text-center">
        <div class="container">
          <span class="icon icon-md icon-default material-icons-flash_on wow fadeInUp"
                style="visibility: visible; animation-name: fadeInUp;"></span>
          <h1 class="section-head-left">Mail us</h1>
          <div class="row text-sm-left">
            <div class="col-sm-6 wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
              <div class="content">
                <div class="content__head">
                  <span class="line">{{$t('Please contact us')}}</span>
                </div>
                <div class="image-wrap shadow-right">
                  <!-- RD Google Map -->
                  <div class="rd-google-map">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2500.628351351256!2d4.717347599999999!3d51.18907249999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c154355661e0ad%3A0xeaeb86174bee6b9e!2sEikenlei%2086%2C%202280%20Grobbendonk!5e0!3m2!1sen!2sbe!4v1680635330021!5m2!1sen!2sbe" width="600" height="450" style="border:0; min-height: 450px;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                  </div>
                  <!-- END RD Google Map -->
                </div>
              </div>
            </div>
            <div class="col-sm-preffix-1 col-sm-5 col-md-4 inset-1 wow fadeInRight"
                 style="visibility: visible; animation-name: fadeInRight;">
              <div class="line-right">
                <h4>{{$t('Please fill in the contact form')}}:</h4>
                <!-- RD Mailform -->
                <form class="rd-mailform"
                      action="https://formspree.io/f/xknyvyla"
                      method="POST"
                      data-aos="flip-up"
                      data-aos-delay="300">
                  <!-- RD Mailform Type -->
                  <input type="hidden" name="form-type" value="contact">
                  <!-- END RD Mailform Type -->
                  <fieldset>
                    <label data-add-placeholder="" class="mfInput">
                      <input type="text" name="name" data-constraints="@NotEmpty @LettersOnly">
                      <span class="mfValidation"></span><span class="mfPlaceHolder">{{$t('Name')}}</span></label>
                    <label data-add-placeholder="" class="mfInput">
                      <input type="text" name="email" data-constraints="@NotEmpty @Email">
                      <span class="mfValidation"></span><span class="mfPlaceHolder">{{$t('Email')}}</span></label>
                    <label data-add-placeholder="" class="mfInput">
                      <textarea name="message" data-constraints="@NotEmpty"></textarea>
                      <span class="mfValidation"></span><span class="mfPlaceHolder">{{$t('Message')}}</span></label>
                    <div class="mfControls btn-group text-center text-sm-left">
                      <button class="btn btn-lg btn-primary" type="submit" data-hover="Send"></button>
                    </div>
                    <div class="mfInfo mfProgress"><span class="cnt"></span><span class="loader"></span><span
                        class="msg"></span></div>
                  </fieldset>
                </form>
                <!-- END RD Mailform -->
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- END Mail us-->
    </main>
    <!--========================================================
                              FOOTER
    ==========================================================-->
    <footer class="page-footer">
      <div class="container">
        <span class="icon icon-md icon-default material-icons-flash_on wow fadeInUp"
              style="visibility: visible; animation-name: fadeInUp;"></span>
        <!-- RD Navbar Brand -->
        <div class="rd-navbar-brand wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
          <a href="" class="rd-navbar-brand__name heading-2">RenoMaar</a>
        </div>
        <!-- END RD Navbar Brand -->
        <p class="wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
          RenoMaar ©
          <span id="copyright-year">2023</span> •
          <a href="/#/terms">{{$t('Terms and Conditions')}}</a> <br>
          <!-- {%FOOTER_LINK} -->
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  title() {
    return 'RenoMaar 🌕' + this.checkAdmin
  },
  name: 'Home',
  data() {
    return {
      loading: true,
      specInterval: 3000,
      //pdfLink: require('src/assets/catalog-en.pdf')
    }
  },
  async mounted() {
    this.specInterval = 3000;
  },
  methods: {},
  computed: {
    ...mapGetters(['checkAuth']),
    checkAdmin: function () {
      if (this.checkAuth) {
        return ' - Admin'
      }
      return ''
    }
  },
}
</script>

<style scoped lang="scss">
.moto-text_system {
  background-position: left top;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 700px;
}

@media (max-width: 280px) {
  .moto-text_system {
    min-height: 500px;
  }
}

.moto-text_system_container {
  background-color: rgb(254, 254, 254);
  position: absolute;
  bottom: 2%;
  padding: 1em;
  margin-right: 1rem;
}

.moto-text_system_12 {
  font-weight: 700;
  font-style: normal;
  font-family: 'Montserrat', sans-serif;
  color: #000000;
  font-size: 22px;
  line-height: 1.1;
  letter-spacing: 0px;
  position: relative;
  top: 1rem;
}

.moto-text_system_12_5 {
  height: 100%;
  border-left-style: solid;
  border-left-width: 2px;
  border-left-color: #d7a46a;
}

@mixin triangle($size, $color, $direction) {
  height: 0;
  width: 0;

  $width: nth($size, 1);
  $height: nth($size, length($size));

  $foreground-color: nth($color, 1);
  $background-color: transparent !default;
  @if (length($color) == 2) {
    $background-color: nth($color, 2);
  }

  @if ($direction == up) or
    ($direction == down) or
    ($direction == right) or
    ($direction == left) {
    $width: $width / 2;

    @if $direction == up {
      border-left: $width solid $background-color;
      border-right: $width solid $background-color;
      border-bottom: $height solid $foreground-color;
    } @else if $direction == right {
      border-top: $width solid $background-color;
      border-bottom: $width solid $background-color;
      border-left: $height solid $foreground-color;
    } @else if $direction == down {
      border-left: $width solid $background-color;
      border-right: $width solid $background-color;
      border-top: $height solid $foreground-color;
    } @else if $direction == left {
      border-top: $width solid $background-color;
      border-bottom: $width solid $background-color;
      border-right: $height solid $foreground-color;
    }
  } @else if ($direction == up-right) or ($direction == up-left) {
    border-top: $height solid $foreground-color;

    @if $direction == up-right {
      border-left: $width solid $background-color;
    } @else if $direction == up-left {
      border-right: $width solid $background-color;
    }
  } @else if ($direction == down-right) or ($direction == down-left) {
    border-bottom: $height solid $foreground-color;

    @if $direction == down-right {
      border-left: $width solid $background-color;
    } @else if $direction == down-left {
      border-right: $width solid $background-color;
    }
  } @else if ($direction == inset-up) {
    border-width: $height $width;
    border-style: solid;
    border-color: $background-color $background-color $foreground-color;
  } @else if ($direction == inset-down) {
    border-width: $height $width;
    border-style: solid;
    border-color: $foreground-color $background-color $background-color;
  } @else if ($direction == inset-right) {
    border-width: $width $height;
    border-style: solid;
    border-color: $background-color $background-color $background-color $foreground-color;
  } @else if ($direction == inset-left) {
    border-width: $width $height;
    border-style: solid;
    border-color: $background-color $foreground-color $background-color $background-color;
  }
}

.sina-content-box-icon {
  padding: 0.8rem;
}

.sina-content-box-title {
  color: #000000;
  font-family: "Nunito", Sans-serif, serif;
  font-size: 20px;
  font-weight: 700;
}

.box {
  width: 100%;
  height: 105%;
  background-color: #fff;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #E0E0E0;
  padding: 0 5px 0 5px;
  text-align: center;
  border-radius: 4px 4px 4px 4px;
}

// Ribbon

[class^="ribbon-"] {
  position: relative;
  margin-bottom: 1.2px;

  &:before,
  &:after {
    content: "";
    position: absolute;
  }
}

.ribon-title {
  font-family: "Nunito", Sans-serif, serif;
  font-size: 19px;
  font-weight: 700;
  letter-spacing: 2.4px;
  color: #f8f8f8;
  position: relative;
  top: 0.6rem;
}

// Ribbon-2
.ribbon-1 {
  width: 150px;
  height: 50px;
  background: #21749a;

  &:before {
    @include triangle(8px, darken(transparent, 0%), down-right);
    top: -8px;
  }

  &:after {
    @include triangle(50px 15px, #21749a, right);
    right: -15px;
  }
}

.ribbon-2 {
  width: 150px;
  height: 50px;
  background: #FF0000;

  &:before {
    @include triangle(8px, darken(transparent, 0%), down-right);
    top: -8px;
  }

  &:after {
    @include triangle(50px 15px, #FF0000, right);
    right: -15px;
  }
}

.ribbon-3 {
  width: 150px;
  height: 50px;
  background: #FCAF17;

  &:before {
    @include triangle(8px, darken(transparent, 0%), down-right);
    top: -8px;
  }

  &:after {
    @include triangle(50px 15px, #FCAF17, right);
    right: -15px;
  }
}

.ribbon-4 {
  width: 150px;
  height: 50px;
  background: #90EE90;

  &:before {
    @include triangle(8px, darken(transparent, 0%), down-right);
    top: -8px;
  }

  &:after {
    @include triangle(50px 15px, #90EE90, right);
    right: -15px;
  }
}


@import "@/styles/global.scss";
</style>
