const state = {
    posts: [],
    foods: [],
    categories: [
        {id: 1, index: 1, name: 'HAIR', desc: ''},
        {id: 2, index: 2, name: 'BEARD', desc: ''}
    ],
}

const getters = {
    allFoods: (state) => state.foods,
    allFoodCategories: (state) => state.categories,
    posts: state => {
        return state.posts;
    }
}

import axios from 'axios';

const actions = {
    setFoods({ commit }, data) {
        commit('setFoods', data)
    },
    removeFoodItem({commit},{id}){
        axios.delete('https://tessenderlo-barbershop-default-rtdb.europe-west1.firebasedatabase.app/' + id + '.json')
            .then(resp => {
                console.log(resp.data)
            }).catch(error => {
            console.log(error);
        });
        commit('removeFood',id)
    },
    removeFoodCategory({commit},{id}){
        console.log('Removing id: ' + id)
        commit('removeCategoryFood',id)
    },
    updateFoodCategories({ commit }, data) {
        commit('updateFoodCategories', data)
    },
    async editFood({ commit }, data) {
        await axios.put('https://tessenderlo-barbershop-default-rtdb.europe-west1.firebasedatabase.app/food/' + data.menu.id + '.json', {
            id : data.menu.id,
            _id: data.menu._id,
            name: data.editedMenu.name,
            type: data.editedMenu.type,
            price: data.editedMenu.price,
            image: data.editedMenu.image ? data.editedMenu.image : '',
            category: data.editedMenu.category,
            desc: data.editedMenu.desc,
            stock: data.editedMenu.stock ? data.editedMenu.stock : 'in'
        }).then(resp => {
            console.log(resp.data);
        }).catch(error => {
            console.log(error);
        });
        commit('editFood', data.editedMenu)
    },
    async loadPosts ({ commit }) {
        const response = await axios.get('https://tessenderlo-barbershop-default-rtdb.europe-west1.firebasedatabase.app/food.json');
        // JSON responses are automatically parsed.
        commit('SET_ITEMS', response.data)
    }
}

const mutations = {
    setFoods: (state, data) => {
        state.foods = data
    },
    SET_ITEMS (state, posts) {
        state.posts = posts
    },
    //remove food item (front-end only not atcual data) because no database yet :(
    removeFood: (state,id) => {
        state.foods.splice(state.foods.findIndex(food => food.id === id ),1)
    },
    removeCategoryFood: (state,id) => {
        state.categories.splice(state.categories.findIndex(category => category.id === id ),1)
    },
    updateFoodCategories: (state, data) => {
        const modifiedIndex = state.categories.map(category => category.index)
        const allIndex = []

        for (let i = 1; i <= state.categories.length; i++) {
            allIndex.push(i)
        }

        let originalIndex = allIndex.filter(index => modifiedIndex.indexOf(index) === -1)[0]

        const swapCategory = state.categories.find(category => {
            if (category.id === data.id) { 
                return false
            }
            return category.index === data.index
        })

        if (swapCategory === undefined || swapCategory === null) { return }

        const updated = state.categories.map(category => {
            if (category.id === swapCategory.id) {
                category.index = originalIndex
            }
            return category
        })

        state.categories = updated
    },
    editFood: (state, data) => {
        state.posts = state.posts.map(post => {
            if (post.id === data.id) {
                return data
            } else {
                return post
            }
        })
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
