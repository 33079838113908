<template>
  <div class="container-nav">
    <div class="sidebar">
      <font-awesome-icon icon="bars" class="sidebar__burger sidebar__icon float-left" v-b-toggle.sidebar-1/>
      <div class="sidebar__middle">
        <ul>
          <li>
            <img :src="require( `@/assets/logo.png`)"
                 @click="goToHome"
                 class="logo-header image media-screen" width="120" height="50">
          </li>
          <li v-for="nav in sortNav" :key="nav.name">
            <router-link :to="nav.routeLink" @click.native="show = false; dim = false; $emit('change-route')">
              {{ $t(nav.name) }}
            </router-link>
          </li>
          <!--li>
            <a href="https://pdfhost.io/v/SkBJwegys_catalog_renomaar" target="_blank">CATALOG</a>
          </li-->
        </ul>
      </div>
      <div class="sidebar__end">
        <b-dropdown id="dropdown-1" variant="outline-secondary" :text="value">
          <b-dropdown-item v-for="option in options"
                           class="languages-selection"
                           :key="option"
                           @click="changeLanguage(option)">
            {{option}}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <b-sidebar
        id="sidebar-1"
        @change="toggleBodyScroll"
        shadow
        backdrop
        no-header
        v-slot:default="{ hide }">
      <div class="sidebar__routes">
        <div class="sidebar__titlebar" @click="hide">
          <font-awesome-icon icon="bars" class="sidebar__icon icon-primary--dark"/>
          <img :src="require( `@/assets/logo.png`)"
               @click="goToHome"
               style="margin: -35px 15px 0 0;"
               width="100" height="50">
        </div>
        <ul class="sidebar__route-container">
          <li v-for="nav in sortNav" :key="nav.name" class="sidebar__router"
              v-bind:class="{'menu-dropdown': nav.child != null}">
            <div class="route-link-container" v-if="nav.child == null" @click="hide">
              <router-link class="sidebar__route-link" :to="nav.routeLink"
                           @click.native="show = false; dim = false; $emit('change-route')">
                <font-awesome-icon :icon="nav.icon" class="sidebar__icon icon-secondary"
                                   v-bind:class="{'home-icon': nav.name === 'Home'}"/>
                <label class="sidebar__router__label">{{ $t(nav.name) }}</label>
              </router-link>
            </div>
            <span v-else>
            <span class="sidebar__collapse"
                  v-b-toggle.collapse="'collapse-' + nav.name.toLocaleLowerCase()"
                  v-on:click="toggleArrow()">
              <font-awesome-icon :icon="nav.icon" class="sidebar__collapse__icon icon-secondary"/>
              <label class="menu">{{ $t(nav.name) }}</label>
              <font-awesome-icon id="arrow" icon="angle-down" class="sidebar__iconright"/>
            </span>
            <b-collapse :id="'collapse-' + nav.name.toLocaleLowerCase()">
                <li v-for="subNav of nav.child" :key="subNav.name">
                  <div class="router-link-container" @click="hide">
                    <router-link class="sidebar__router submenu"
                                 :to="subNav.routeLink" @click.native="show = false;
                                     dim = false; $emit('change-route')"
                                 v-bind:class="{'food-submenu': subNav.name === 'Food'}">
                      {{ $t(subNav.name) }}
                    </router-link>
                  </div>
                </li>
            </b-collapse>
            </span>
          </li>
          <!--li class="sidebar__router">
            <div class="route-link-container">
              <a class="sidebar__route-link" href="https://pdfhost.io/v/SkBJwegys_catalog_renomaar" target="_blank">CATALOG</a>
            </div>
          </li-->
        </ul>
        <div class="menu__language-selection">
          <b-button class="menu__language btn-info" type="submit"
                    @click="$i18n.locale = 'en'">
            {{ $t('EN') }}
          </b-button>
          <b-button class="menu__language btn-danger" type="submit"
                    @click="$i18n.locale = 'nl'">
            {{ $t('NL') }}
          </b-button>
        </div>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'navbar',
  data: function () {
    return {
      options: ['en', 'nl'],
      value: 'en',
      navData: [
        {
          name: 'HOME',
          href: '/'
        },
        {
          name: 'ABOUT',
          href: '/about'
        },
        {
          name: 'GALLERY',
          href: '/gallery'
        },
        {
          name: 'CONTACT',
          href: '/contact'
        },
        {
          name: 'TERMS',
          href: '/terms'
        },
      ],
      show: false,
      dim: false
    }
  },
  methods: {
    toggleArrow: function () {
      document.getElementById("arrow").classList.toggle("flip");
    },
    enableScrollToParent: function () {
      this.show = false;
      this.dim = false;
      this.$emit('change-route');
    },
    toggleBodyScroll(visible) {
      if (visible) {
        document.body.classList.add('overflow-hidden')
      } else {
        document.body.classList.remove('overflow-hidden')
      }
    },
    goToHome() {
      this.$router.replace('/')
          .then(() => {
            this.$router.go(0);
          })
          .catch(() => {
            // already pointing to the same route, so just refresh
            this.$router.go(0)
          })
    },
    changeLanguage: function (lng) {
      this.value = lng;
      this.$i18n.locale = lng
    },
  },
  computed: {
    ...mapGetters(['allOrders', 'allNav', 'checkAuth']),
    sortNav: function () {
      if (this.checkAuth) {
        return this.allNav.filter(function (nav) {
          return nav.permission.includes('admin') ? nav : null
        })
      } else {
        return this.allNav.filter(function (nav) {
          return nav.permission.includes('user') ? nav : null
        })
      }
    },
    totalRequest() {
      return this.allOrders.reduce((acc, item) => acc + item.quantity, 0);
    }
  }
}
</script>

<style lang="scss" scoped>

.basket-icon {
  width: 30px;
  position: relative;
  top: -5px;
  cursor: pointer;
}

.cart-value {
  transition-property: all;
  transition-duration: .25s;
  transition-timing-function: ease-out;
  font-size: 1.4rem;
  font-family: "Oswald", sans-serif;
  border-left: 1px solid var(--color-line);
  margin-left: 0.75rem;
  padding-left: 0.75rem;
  border-left: 3px solid;
  font-weight: 900;
  color: #8753ff;
}

.logo-header {
  cursor: pointer;
  position: fixed;
  top: 0;
  left: 5%;
  width: 10rem;
  height: 10rem;
}

.module-logo.vertical {
  background-color: #212224;
  width: 212px;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  transition-duration: .6s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  position: relative;
  margin-right: 3rem;
  z-index: 1000;
}

#dropdown-1 {
  display: inline-block;
  margin-right: 15px;
}

.sidebar {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  background-color: #fefefe;
  width: 100%;
  z-index: 120;
  min-width: 300px;
  padding: 2.5em 4em 2.5em 8em;

  &__routes {
    display: flex;
    flex-direction: column;
  }

  &__icon {
    background: #ffffff;
    border-style: none;
    font-size: 18px;
    cursor: pointer;
    margin-right: 1.3rem;
  }

  &__burger {
    display: none;
  }

  &__iconright {
    justify-content: flex-end;
    margin-left: auto;
  }

  &__route-link {
    color: #5c5c5c;
    width: 100%;
    display: block;
    padding: 1rem 1rem;
    font-family: Oswald, sans-serif;
    border-bottom: 1px solid #efefef;

    &:hover {
      text-decoration: none;
    }
  }

  &__icon:focus,
  &__iconright:focus,
  &__collapse:focus {
    outline: none !important;
    box-shadow: none !important;
  }

  &__titlebar {
    display: flex;
    padding: .8rem .5rem .8rem 2rem;
    height: 55px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;

    .sidebar__icon {
      height: auto;
    }

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  &__route-container {
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .1rem;
  }

  &__navtitle {
    font-size: 2em;
    letter-spacing: 15px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;

    &:hover {
      cursor: pointer;
    }
  }

  &__title {
    font-size: 18px;
    padding: 0;
    margin: 0;
    padding-left: 0.295rem;
    margin-block-start: 0.21em;
    font-weight: 600;
    font-family: "Roboto Slab", serif;
    letter-spacing: 5px;
    color: $sidebar-color-title;
  }

  &__logo-text {
    position: relative;
    top: 4px;
    left: 4px;
  }

  &__router {
    font-size: 14px;
    color: #5c5c5c;
    list-style: none;

    &__label {
      margin: 0;
      pointer-events: none;
    }

    &:hover {
      cursor: pointer;
      text-decoration: none;
      color: $sidebar-color-hover;
      background: $sidebar-bg-hover;
    }

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .router-link-exact-active {
      font-size: 15px !important;

      svg {
        color: #8753ff;
      }
    }
  }

  &__end {
    justify-content: flex-end;
    margin-left: auto;

    .sidebar__icon {
      color: #ffffff;
      margin: 0;
    }
  }

  &__middle {
    justify-content: center;
    margin-left: auto;

    ul {
      list-style: none;
      margin: 0 0;
      padding: 0 0;

      li {
        display: inline-block;

        a {
          font-size: 15px;
          font-weight: 300;
          line-height: 30px;
          color: #363c3f;
          font-family: "Oswald", sans-serif;
          letter-spacing: .1em;
          box-sizing: border-box;
          text-transform: uppercase;
          margin-right: 45px;
        }
      }
    }
  }

  &__end-shop {
    color: $navbar-color;
  }

  &__collapse {
    display: flex;
    align-items: center;
    padding-left: 1.9rem;
    padding-right: 1.9rem;
    padding-bottom: .8rem;
  }

  &__orders-badge {
    position: relative;
    background: $notif;
    color: $primary-color;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    font-size: 10px;
    pointer-events: none;
    bottom: 5px;
    left: 15px;
  }

  &__orders-badge-text {
    position: absolute;
    bottom: -0.5px;
    width: 100%;
    text-align: center;
    pointer-events: none;
  }
}

.sidebar__middle ul li a:hover {
  color: #8753ff;
}

.router-link-exact-active {
  font-size: 1em !important;
  font-weight: bold !important;
  color: #8753ff !important;
  border-bottom: 4px solid #8753ff;
  padding-bottom: 4px !important;

  svg {
    color: #8753ff;
  }
}

.menu {
  margin: 0;
  padding-left: .22rem;
}

.onlyMobile {
  display: none;
}

.menu:hover {
  background: transparent;
  cursor: pointer;
}

.submenu {
  display: flex;
  padding-left: 5.7rem;
  padding-bottom: 0.94rem;
  padding-top: 0.8rem;
}

.icon-secondary {
  margin-right: 1.56rem;
}

.home-icon {
  margin-right: 1.41rem;
}

.screen--dim {
  // display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #343a40;
  opacity: 0.6;
  z-index: 100;
}

.flip {
  transform: scaleY(-1);
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.food-submenu {
  margin-top: 0rem;
}

.menu-dropdown {
  padding: .8rem 0 0 0;
}

.float-left {
  float: left;
  margin-right: auto;
}

.menu__language-selection {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.menu__language {
  margin-left: 5px;
  font-weight: 700;
  color: #fff;
  text-decoration: none;
  transition: .35s ease-in-out;
  width: 100px;
  opacity: 0.85;
}

@media all and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
  .sidebar {

    &__navtitle {
      margin: auto;
      padding-left: 15px;
      font-size: 24px;
      letter-spacing: 15px;
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;

      &:hover {
        cursor: pointer;
      }

      img {
        position: relative;
        top: -20px;
        height: 40px;
        width: 40px;
      }
    }

    &__burger {
      display: block;
    }

    &__logo-text {
      position: relative;
      top: -5px;
      left: 0;
    }

    &__end-shop {
      position: relative;
      top: 0;
    }
  }

  .onlyMobile {
    display: inline-block;
    font-weight: 800;
    margin-left: 8px;
    position: relative;
    top: -10px;
  }

  .sidebar__middle {
    display: none;
  }
}

@media screen and (max-width: 700px) {
  .sidebar {

    &__navtitle {
      margin: auto;
      padding-left: 15px;
      font-size: 24px;
      letter-spacing: 15px;
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;

      &:hover {
        cursor: pointer;
      }

      img {
        position: relative;
        top: -8px;
        height: 40px;
        width: 40px;
      }
    }

    &__burger {
      display: block;
    }

    &__logo-text {
      position: relative;
      top: -5px;
      left: 0;
    }

    &__end-shop {
      position: relative;
      top: 0;
    }
  }

  .onlyMobile {
    display: inline-block;
    font-weight: 800;
    margin-left: 8px;
    position: relative;
    top: -12px;
  }

  .sidebar__middle {
    display: none;
  }

  .router-link-exact-active {
    font-size: 1em !important;
    font-weight: 700 !important;
    color: #8753ff !important;
    border-bottom: none;
    padding-bottom: 4px !important;

    svg {
      color: #8753ff;
    }
  }

  .sidebar__titlebar {
    text-align: center;
    padding: 5rem 2rem;
    display: block;
    background: #f3f4f4;

    svg {
      float: left;
    }
  }
}

@media screen and (max-width: 400px) {
  .sidebar {
    padding: 2em 2em 2em 2em;

    &__navtitle {
      margin: auto;
      padding-left: 15px;
      font-size: 24px;
      letter-spacing: 15px;
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;

      &:hover {
        cursor: pointer;
      }

      img {
        position: relative;
        top: -20px;
        height: 40px;
        width: 40px;
      }
    }

    &__burger {
      display: block;
    }

    &__logo-text {
      position: relative;
      top: -5px;
      left: 0;
    }

    &__end-shop {
      position: relative;
      top: 0;
    }
  }

  .onlyMobile {
    display: inline-block;
    font-weight: 800;
    margin-left: 8px;
    position: relative;
    top: -12px;
  }

  .sidebar__middle {
    display: none;
  }
}
</style>
