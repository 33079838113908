<template>
  <main class="page-content" style="padding-top: 4% !important;">
    <!-- About -->
    <section class="well-md text-center">
      <div class="container">
        <span class="icon icon-md icon-default material-icons-flash_on wow fadeInUp"
              style="visibility: visible; animation-name: fadeInUp;"></span>
        <h1 class="section-head-left">{{$t('About')}}</h1>
        <div class="row text-sm-left">
          <div class="col-sm-6 wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
            <div class="image-wrap shadow-right">
              <img src="@/assets/about/lg_page-2_img01.jpg" data-srcset-base="@/assets/about/" data-srcset-ext="_page-2_img01.jpg"
                   data-srcset="sm 1101w, md 1369w, lg 1400w" alt="" width="570" height="570">
            </div>
          </div>
          <div class="col-sm-preffix-1 col-sm-5 col-md-4 inset-1 wow fadeInRight"
               style="visibility: visible; animation-name: fadeInRight;">
            <div class="line-right">
              <h4>{{$t('Our history')}}</h4>
              <p class="wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">{{$t('about-us-1')}}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- END About-->

    <!-- Mission -->
    <section class="well-lg text-center">
      <div class="container">
        <span class="icon icon-md icon-default material-icons-flash_on wow fadeInUp"
              style="visibility: visible; animation-name: fadeInUp;"></span>
        <h1 class="section-head-right">{{$t('Mission')}}</h1>
        <div class="row text-sm-left">
          <div class="col-sm-6 col-md-preffix-1 col-sm-push-2 wow fadeInUp"
               style="visibility: visible; animation-name: fadeInUp;">
            <div class="image-wrap shadow-left">
              <img src="@/assets/about/lg_page-2_img02.jpg" data-srcset-base="@/assets/about/" data-srcset-ext="_page-2_img02.jpg"
                   data-srcset="sm 1101w, md 1369w, lg 1400w" alt="" width="570" height="570">
            </div>
          </div>
          <div class="col-sm-preffix-1 col-sm-5 col-md-4 inset-1 col-sm-push-1 wow fadeInLeft"
               style="visibility: visible; animation-name: fadeInLeft;">
            <div class="line-left">
              <h4>{{$t('Mission')}}</h4>
              <p class="wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">{{$t('our-mission')}}</p>
            </div>
          </div>
        </div>
        <span class="icon icon-md icon-default material-icons-flash_on wow fadeInUp"
              style="visibility: hidden; animation-name: none;"></span>
      </div>
    </section>
    <!-- END Stylists-->

    <!-- Goal -->
    <section class="well-md text-center">
      <div class="container">
        <span class="icon icon-md icon-default material-icons-flash_on wow fadeInUp"
              style="visibility: visible; animation-name: fadeInUp;"></span>
        <h1 class="section-head-left">GOAL</h1>
        <div class="row text-sm-left">
          <div class="col-sm-6 wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
            <div class="image-wrap shadow-right">
              <img src="@/assets/about/lg_page-2_img05.jpg" data-srcset-base="@/assets/about/" data-srcset-ext="_page-2_img01.jpg"
                   data-srcset="sm 1101w, md 1369w, lg 1400w" alt="" width="570" height="570">
            </div>
          </div>
          <div class="col-sm-preffix-1 col-sm-5 col-md-4 inset-1 wow fadeInRight"
               style="visibility: visible; animation-name: fadeInRight;">
            <div class="line-right">
              <h4>{{$t('Our GOAL')}}</h4>
              <p class="wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">{{$t('our-goal')}}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import {gsap} from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'

export default {
  title: 'RenoMaar 🌕 - About',
  name: 'Home',
  data: function () {
    return {
      slide: 0,
      sliding: null
    }
  },
  mounted: function () {
    this.startAnimation();
  },
  methods: {
    startAnimation: function () {

      gsap.registerPlugin(ScrollTrigger)

      gsap.to(".about__garis", {duration: 1, opacity: 1, ease: "power2.in"})

      gsap.to(".banner", {
        scrollTrigger: {
          trigger: ".banner",
          scrub: true,
          start: "50% 80%",
          end: "bottom -100%",
          toggleClass: "active",
          ease: "power2"
        }
      });

      gsap.to(".banner__image", {
        scrollTrigger: {
          trigger: ".container-container",
          scrub: 0.5,
          start: "top bottom",
          end: "bottom -300%",
          ease: "power2"
        },
        y: "-30%"
      });

      gsap.to(".about__left", {duration: 1, opacity: 1, ease: "power2.in"}, 1)

      const tlLocation = gsap.timeline({
        scrollTrigger: {
          trigger: ".about__column__container",
          start: 'top 75%',
          end: 'bottom 25%',
        }
      });
      tlLocation.fromTo(".about__column", {
        y: 25,
        opacity: 0,
        stagger: 0.1,
        scrub: 0.5,
      }, {y: 0, opacity: 1}, 0)
    },

    onSlideStart() {
      this.sliding = true
    },
    onSlideEnd() {
      this.sliding = false
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/global.scss";
</style>
