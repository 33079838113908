<template>
  <main class="page-content" style="padding-top: 5.5rem !important;">
    <!-- About -->
    <section>
      <div class="terms-container">
        <h1 class="terms-title">{{ $t('Terms and Conditions') }}</h1>
        <div class="terms-content">
          <h2 class="terms-section-title">{{ $t('INTRODUCTION') }}</h2>
          <p class="terms-text">{{$t('terms-1')}}.</p>

          <h2 class="terms-section-title">{{ $t('SERVICES') }}</h2>
          <p class="terms-text">{{$t('terms-2')}}.</p>

          <h2 class="terms-section-title">{{ $t('PAYMENT') }}</h2>
          <p class="terms-text">{{$t('terms-3')}}.</p>

          <h2 class="terms-section-title">{{ $t('WARRANTY') }}</h2>
          <p class="terms-text">{{$t('terms-4')}}.</p>

          <h2 class="terms-section-title">{{ $t('TERMINATION') }}</h2>
          <p class="terms-text">{{$t('terms-5')}}.</p>

          <h2 class="terms-section-title">{{ $t('GOV. LAWS') }}</h2>
          <p class="terms-text">{{$t('terms-6')}}.</p>

          <h2 class="terms-section-title">{{ $t('Changes to Terms and Conditions') }}</h2>
          <p class="terms-text">.</p>

          <h2 class="terms-section-title">{{ $t('CONTACT INFORMATION') }}</h2>
          <p class="terms-text">{{$t('terms-7')}}.</p>
        </div>
      </div>
    </section>
    <!-- END About-->
  </main>
</template>

<script>
import {gsap} from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'

export default {
  title: 'RenoMaar 🌕 - About',
  name: 'Home',
  data: function () {
    return {
      slide: 0,
      sliding: null
    }
  },
  mounted: function () {
    this.startAnimation();
  },
  methods: {
    startAnimation: function () {

      gsap.registerPlugin(ScrollTrigger)

      gsap.to(".about__garis", {duration: 1, opacity: 1, ease: "power2.in"})

      gsap.to(".banner", {
        scrollTrigger: {
          trigger: ".banner",
          scrub: true,
          start: "50% 80%",
          end: "bottom -100%",
          toggleClass: "active",
          ease: "power2"
        }
      });

      gsap.to(".banner__image", {
        scrollTrigger: {
          trigger: ".container-container",
          scrub: 0.5,
          start: "top bottom",
          end: "bottom -300%",
          ease: "power2"
        },
        y: "-30%"
      });

      gsap.to(".about__left", {duration: 1, opacity: 1, ease: "power2.in"}, 1)

      const tlLocation = gsap.timeline({
        scrollTrigger: {
          trigger: ".about__column__container",
          start: 'top 75%',
          end: 'bottom 25%',
        }
      });
      tlLocation.fromTo(".about__column", {
        y: 25,
        opacity: 0,
        stagger: 0.1,
        scrub: 0.5,
      }, {y: 0, opacity: 1}, 0)
    },

    onSlideStart() {
      this.sliding = true
    },
    onSlideEnd() {
      this.sliding = false
    }
  }
}
</script>

<style scoped lang="scss">
.terms-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 50px;
  background-color: #f5f5f5;
  border-radius: 10px;
}

.terms-title {
  font-size: 36px;
  text-align: center;
  margin-bottom: 50px;
  color: #333;
}

.terms-content {
  font-size: 18px;
  line-height: 1.5;
  color: #555;
}

.terms-section-title {
  font-size: 24px;
  margin-top: 50px;
  margin-bottom: 20px;
  color: #333;
}

.terms-text {
  margin-bottom: 20px;
}
@import "@/styles/global.scss";
</style>
