<template>
  <div class="container-fluid" style="padding-top: 5.5rem">
    <section>
      <div id="content">
        <section class="section section-location section-md bg-transparent default">
          <div class="container">
            <div class="row align-items-center justify-content-center gutters-lg">
              <div class="mb-6 col-md-6">
                <div class="mb-12 mb-lg-0 col-md-12"
                     style="margin-bottom: 1.2rem !important; border-bottom: 0.01rem solid green; padding-bottom: 1.1rem;">
                  <h4 class="mb-0">RenoMaar</h4>
                  <span class="text-muted">Eikenlei 86, 2280 Grobbendonk</span>
                  <hr class="hr-md">
                  <div class="row">
                    <div class="col-sm-6 mb-3 mb-sm-0 phone">
                      <h6 class="mb-1 text-muted">{{ $t('Phone') }}:</h6>
                      <div @click="callPhone()">+32 4 88594213</div>
                    </div>
                    <div class="col-sm-6">
                      <h6 class="mb-1 text-muted">E-mail:</h6> <a href="mailto:hello@example.com">info@renomaar.be</a>
                    </div>
                  </div>
                </div>
                <div class="mb-12 mb-lg-0 col-md-12">
                  <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2500.628351351256!2d4.717347599999999!3d51.18907249999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c154355661e0ad%3A0xeaeb86174bee6b9e!2sEikenlei%2086%2C%202280%20Grobbendonk!5e0!3m2!1sen!2sbe!4v1680635330021!5m2!1sen!2sbe"
                      width="600" height="450" style="border:0; min-height: 450px;" allowfullscreen="" loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
              </div>
              <div class="mb-6 col-md-6">
                <h4>{{$t('Please fill in the contact form')}}:</h4>

                <!-- RD Mailform -->
                <form class="rd-mailform"
                      action="https://formspree.io/f/xknyvyla"
                      method="POST"
                      data-aos="flip-up"
                      data-aos-delay="300">
                  <!-- RD Mailform Type -->
                  <input type="hidden" name="form-type" value="contact">
                  <!-- END RD Mailform Type -->
                  <fieldset>
                    <label data-add-placeholder="" class="mfInput">
                      <input type="text" name="name" data-constraints="@NotEmpty @LettersOnly">
                      <span class="mfValidation"></span><span class="mfPlaceHolder">{{$t('Name')}}</span></label>
                    <label data-add-placeholder="" class="mfInput">
                      <input type="text" name="email" data-constraints="@NotEmpty @Email">
                      <span class="mfValidation"></span><span class="mfPlaceHolder">{{$t('Email')}}</span></label>
                    <label data-add-placeholder="" class="mfInput">
                      <textarea name="message" data-constraints="@NotEmpty"></textarea>
                      <span class="mfValidation"></span><span class="mfPlaceHolder">{{$t('Message')}}</span></label>
                    <div class="mfControls btn-group text-center text-sm-left">
                      <button class="btn btn-lg btn-primary" type="submit" data-hover="Send"></button>
                    </div>
                    <div class="mfInfo mfProgress"><span class="cnt"></span><span class="loader"></span><span
                        class="msg"></span></div>
                  </fieldset>
                </form>
                <!-- END RD Mailform -->
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  </div>
</template>

<script>

import axios from 'axios';

export default {
  title: 'user - contact us',
  data: function () {
    return {}
  },
  mounted() {
  },
  created() {
  },
  methods: {
    async getDataFromReservationApi() {
      const res = await axios.get(`https://tessenderlo-barbershop-default-rtdb.europe-west1.firebasedatabase.app/reservations.json`)
      this.reservationData = res.data.map(data => {
        console.log(data)
        return data
      })
    },
    submit: function () {

    },
    callPhone() {
      window.open('tel:00324 88594213')
    }
  },
  computed: {}
}
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";
@import "@/styles/extra.scss";
.phone{
  &:hover{
    cursor: pointer;
  }
}

.contact-us-label {
  padding: 85px 0 0 0;
  font-weight: 900;
  text-transform: uppercase;
}

.eltdf-row-grid-section {
  width: 90%;
  position: relative;
  margin: 0 auto;
  z-index: 20;
  font-family: Roboto, sans-serif;
  font-size: 15px;
  line-height: 25px;
  font-weight: 400;
  letter-spacing: .01em;
  overflow-x: hidden !important;
  -webkit-font-smoothing: antialiased;
}

.vc_custom_1531135406833 {
  padding-top: 85px !important;
  padding-bottom: 100px !important;
  margin-left: -15px;
  margin-right: -15px;
}

.vc_column-inner {
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

.eltdf-il-icon-holder {
  display: inline-block;
  margin-right: 12px;
}

.eltdf-il-text {
  position: relative;
  display: inline-block;
  color: #878787;
}

.screen-reader-response {
  position: absolute;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  width: 1px;
  margin: 0;
  padding: 0;
  border: 0;
}

.eltdf-st-text {
  color: #111;
  font-family: Bebas, ui-serif;
  font-weight: 400;
  word-spacing: 0.15em;
  display: block;
  margin: 22px 0 0;
  width: 100%;
}

.eltdf-icon-list-holder {
  position: relative;
  display: table;
  table-layout: fixed;
  height: auto;
  width: 100%;
  margin-bottom: 8px;
  font-family: Roboto, sans-serif;
  font-size: 15px;
  line-height: 25px;
  font-weight: 400;
  letter-spacing: .01em;
  color: #878787;
}

.eltdf-st-title {
  color: #111;
  font-family: Bebas, serif;
  font-weight: 800;
  text-transform: uppercase;
  font-size: 2.58em;
  word-spacing: 0.15em;
}

.wpcf7-form-control {
  position: relative;
  width: 100%;
  font-weight: 900;
  margin: 0 0 20px;
  padding: 20px 29px !important;
  font-family: inherit;
  font-size: 1.5em;
  line-height: 20px;
  color: #111;
  background-color: transparent;
  border: 1px solid #111;
  border-radius: 0;
  outline: 0;
  -webkit-appearance: none;
  cursor: text;
  box-sizing: border-box;
  resize: none;
  transition: border-color .2s ease-in-out;
}

/*.bg.container-fluid {
  background: url("../assets/contact-us-2.png") center center no-repeat;
  background-size: cover;
  background-position: 50% 0px;
}*/

.content {
  background-color: rgba(255, 255, 255, 0.75);
  color: white;

  @media (min-width: 768px) {
    background-color: rgba(255, 255, 255, 0.5);
  }

  @media (min-width: 1200px) {
    background-color: transparent;
  }
}

.bg-transparent {
  background-color: white !important;
}

.form-control {
  background-color: white !important;
}

h1 {
  text-align: center;

  @media (min-width: 768px) {
    text-align: initial;
  }
}

@media only screen and (max-width: 700px) {
  .contact-us-label {
    padding: 0 0 0 0;
    font-weight: 600;
    color: black;
    text-transform: uppercase;
  }

  .vc_custom_1531135406833 {
    padding-top: 50px !important;
    padding-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .eltdf-row-grid-section {
    width: 100%;
  }
}

.page-title {
  padding: 4rem 0 5rem;
  background-color: #f3f4f4;
}

.container-title {
  font-size: 4.75rem;
  font-family: "Helvetica Neue", "Raleway", sans-serif;
}

</style>
