const state = {
    reservations: [
    ],
    reservatie:[
        {
            key: 'id',
            variant: 'success',
            sortable: true
        },
        {
            key:'name'
        },
        {
            key:'phone'
        },
        {
            key:'person'
        },
        {
            key:'date',
        },
        {
            key:'time'
        }
    ]
}

const getters = {
    reservatie: (state) => state.reservatie
}

const actions = {

}

const mutations = {

}
 
export default{
    state,
    getters,
    actions,
    mutations
}