const state = {
    drinks: [
        {id: 'c1', name: 'Liquid Crack', price:'150000', image:'liquidcrack', category: 'COCKTAILS', desc:'Something shimmery, addictive and cheeky. Filled with all things magick to get your MOJO going again.'},
        {id: 'c2', name: 'White Russian', price:'120000', image:'whiterussian', category: 'COCKTAILS', desc:'Vodka, coffee liqueur, fresh milk, heavy cream.'},
        {id: 'c3', name: 'Paloma Milk Punch', price:'140000', image:'palomamilkpunch', category: 'COCKTAILS', desc:'Tequila, liqueur, lime, grapefruit juice, coconut milk, topped with soda.'},
        {id: 'c4', name: 'Ni Hao Shimeji', price:'140000', image:'nihaoshimeji', category: 'COCKTAILS', desc:'Shimeji infused vodka, campari, vermouth, celery bitters, passion fruit syrup, lamb and rosemary gravy.'},
        {id: 'c5', name: 'I Love You Beary Much', price:'135000', image:'iloveyoubearymuch', category: 'COCKTAILS', desc:'Vodka infused with lyche juice and gummy bear to express your love.'},
        {id: 'c6', name: 'Adult Milo Dinosaur', price:'135000', image:'adultmilodinosaur', category: 'COCKTAILS', desc:'Vodka, milo, fresh milk, hershey’s syrup, pure cocoa.'},
        {id: 'c7', name: 'Tequila Sunrise', price:'110000', image:'tequilasunrise', category: 'COCKTAILS', desc:'Tequila, orang juice, grenadine syrup.'},
        {id: 'c8', name: 'Garden Margarita', price:'150000', image:'gardenmargarita', category: 'COCKTAILS', desc:'Butter corn and undoubtly twisted with Tequile, Cointreau, palm sugar and olive bitters and corn!'},
        {id: 'c9', name: 'The Secret Mermaid', price:'150000', image:'thesecretmermaid', category: 'COCKTAILS', desc:'A variation of Daiquiri, Spice & Everything Nice. Unconventionally uses Bon Cabe and creamy coconut, mango.'},
        {id: 'c10', name: 'Coca Me Maybe', price:'140000', image:'colamemaybe', category: 'COCKTAILS', desc:'Lemongrass infused gin, lychee, earl grey tea, homemade lemon ginger honey.'},
        {id: 'c11', name: 'Crazy Rich Sesame', price:'140000', image:'crazyrichsesame', category: 'COCKTAILS', desc:'Gin, whiskey cinnamon liqueur, ginger syrup, lemon, black sesame paste, egg white.'},
        {id: 'c12', name: 'Candy Corn Martini with Pop Rocks Rim', price:'120000', image:'candycornmartini', category: 'COCKTAILS', desc:'Vodka, martini, vermouth, candy, with pop rocks rim.'},
        {id: 'c13', name: 'Ocha Negroni', price:'135000', image:'ochanegroni', category: 'COCKTAILS', desc:'Whiskey infused with strawberry juice and topped with flowers fragrant'},
        {id: 'c14', name: 'XXL Joy Punch!', price:'135000', image:'xxljoypunch', category: 'COCKTAILS', desc:'Rum, apple, strawberry, soursop juice, apple syrup, topped with a bottle of soju.'}
    ],
    categories: [
        {id: 1, index: 1, name: 'COCKTAILS', desc: 'Our cocktail menu is seasonal. It changes periodically, depending on the best seasonal ingredients that are available on the market.'}
    ]
}

const getters = {
    allDrinks: (state) => state.drinks,
    allDrinkCategories: (state) => state.categories.sort((a, b) => a.index - b.index)
}

const actions = {
    removeBeverageItem({commit},{id}){
        console.log('Removing id: ' + id)
        commit('removeBeverage',id)
    },
    removeBeverageCategory({commit},{id}){
        console.log('Removing id: ' + id)
        commit('removeCategoryBeverage',id)
    },
    updateDrinkCategories({ commit }, data) {
        console.log('Updating for id: ' +  data.id + ' with new index: ' + data.index)
        commit('updateDrinkCategories', data)
    },
    editDrink({ commit }, data) {
        console.log('Edited for id: ' + data.menu.id)
        console.log('Edited fields: ' + data.editedMenu.name + ' price: ' + data.editedMenu.price + ' category: ' + data.editedMenu.category + ' desc: ' + data.editedMenu.desc);
        commit('editDrink', data.editedMenu)
    }
}

const mutations = {
    //remove drink beverage (front-end only not atcual data) because no database yet :(
    removeBeverage: (state,id) => {
        state.drinks.splice(state.drinks.findIndex(drink => drink.id == id ),1)
    },
    removeCategoryBeverage: (state,id) => {
        state.categories.splice(state.categories.findIndex(category => category.id == id ),1)
    },
    updateDrinkCategories: (state, data) => {
        const modifiedIndex = state.categories.map(category => category.index)
        const allIndex = []

        for (let i = 1; i <= state.categories.length; i++) {
            allIndex.push(i)
        }

        let originalIndex = allIndex.filter(index => modifiedIndex.indexOf(index) === -1)[0]

        const swapCategory = state.categories.find(category => {
            if (category.id == data.id) { 
                return false
            }
            return category.index == data.index
        })

        if (swapCategory === undefined || swapCategory === null) { return }

        const updated = state.categories.map(category => {
            if (category.id == swapCategory.id) {
                category.index = originalIndex
            }
            return category
        })

        state.categories = updated
    },
    editDrink: (state, data) => {
        state.drinks = state.drinks.map(drink => {
            if (drink.id == data.id) {
                return data
            } else {
                return drink
            }
        })
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}