import Vue from 'vue';
import VueCarousel from 'vue-carousel';
import { Pano } from 'vuejs-vr';
import App from './App.vue';
import router from './router';
import VueMaterial from 'vue-material';
import 'vue-material/dist/vue-material.min.css';
import { library } from '@fortawesome/fontawesome-svg-core';//
// fa-map-marker-alt
import {
    faUser,
    faEgg,
    faPeopleCarry,
    faCloud,
    faEuroSign,
    faStar,
    faAirFreshener,
    faTrash,
    faPhone,
    faGlobe,
    faMapMarkerAlt,
    faImage,
    faUpload,
    faArrowCircleUp,
    faArrowLeft,
    faSearch,
    faListUl,
    faEdit,
    faChevronRight,
    faAngleDown,
    faMinus,
    faPlus,
    faGlassMartiniAlt,
    faEye,
    faEyeSlash,
    faShoppingBag,
    faShoppingCart,
    faBullhorn,
    faCreditCard,
    faBars,
    faHome,
    faUtensils,
    faUserCircle,
    faInfoCircle,
    faTasks,
    faTable,
    faMugHot,
    faArrowAltCircleRight,
    faSignOutAlt,
    faTimesCircle,
    faCopy,
    faWineBottle,
    faEnvelope,
    faHardHat,
    faChessQueen,
    faCheck,
    faTimes,
    faTh, faShoppingBasket, faExclamationTriangle, faAsterisk
} from '@fortawesome/free-solid-svg-icons';
import {faTwitter, faFacebook, faInstagram, faGg, faHornbill} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import BootstrapVue from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import store from './store'
import titleMixin from './mixins/titleMixin'
import axios from 'axios'
import VueAxios from 'vue-axios'
import i18n from './i18n'
import {faBookmark, faCheckSquare, faClock, faHourglass} from "@fortawesome/free-regular-svg-icons";

library.add(
              faListUl,
              faExclamationTriangle,
              faCheckSquare,
              faCheck,
              faUser,
              faClock,
              faEnvelope,
              faTimes,
              faEgg,
              faHardHat,
              faWineBottle,
              faPeopleCarry,
              faEuroSign,
              faCloud,
              faStar,
              faAsterisk,
              faAirFreshener,
              faTrash,
              faGg,
              faChessQueen,
              faGlobe,
              faPhone,
              faMapMarkerAlt,
              faImage,
              faUpload,
              faArrowCircleUp,
              faArrowLeft,
              faSearch,
              faEdit,
              faEye,
              faShoppingBasket,
              faEyeSlash,
              faBars,
              faShoppingBag,
              faShoppingCart,
              faBullhorn,
              faCreditCard,
              faHome,
              faTh,
              faUtensils, 
              faUserCircle, 
              faAngleDown,
              faInfoCircle,
              faTasks,
              faTable,
              faCloud,
              faMugHot,
              faGlassMartiniAlt,
              faMinus,
              faPlus,
              faArrowAltCircleRight,
              faTwitter,
              faFacebook,
              faInstagram,
              faChevronRight,
              faSignOutAlt,
              faTimesCircle,
              faHourglass,
              faCopy,
              faBookmark,
              faHornbill,
            );
Vue.use(VueMaterial);
Vue.use(BootstrapVue);
Vue.use(VueAxios, axios)
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.use(VueCarousel);
Vue.use(Pano);

Vue.mixin(titleMixin)

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
